const baseUrl = 'https://focaliuat.azurewebsites.net';
const tinyMCEapiKey = 'lgfwpus55ko7blzqgvgitho6wwy9w3ap5rsgots1mcd2r5ku';

export const environment = {
  appVersion: require('../../package.json').version + '-uat',
  production: true,
  tinyMCEapiKey:tinyMCEapiKey,
  hmr: false,
  application: {
    baseUrl,
    name: 'Focali',
    logoUrl: 'assets/img/focali_logo.png',
  },
  oAuthConfig: {
    issuer: 'https://focali-uat.azurewebsites.net',
    redirectUri: baseUrl,
    clientId: 'AgencyPlatform_App',
    dummyClientSecret: '1q2w3e*',
    scope: 'offline_access AgencyPlatform',
  },
  apis: {
    default: {
      url: 'https://focali-uat.azurewebsites.net',
      rootNamespace: 'AgencyPlatform',
    },
  },
  appInsights: {
    instrumentationKey: '902fb647-26d3-41dd-a2be-25906a4bfdbb',
  },
  googleAnalytics: {
    measurementId: '',
  },
  webPush: {
    publicKey:
      'BEL-O4IUpkHPn3V4vB9OX9c2o-KIq-yIT6ilzJCZ372RN_iUR18PzYxNGFCl5oJtabTHPGEguEepictUHzddolE',
    privateKey: 'ywN0xjozJYIx2HKiPaWxFC6NKNSMi7xH55Dy4k429Gg',
  },
};
