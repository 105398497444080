import { AppMenuDto, CrudAction } from './app.model';
// defaultModule : 'pages'
export const transactionMenus = [
  {
    path: 'proforma',
    iconClass: 'file-sync',
    module: 'pages',
    lookupFieldCode: 'vesselName',
    lookupFieldName: 'pdaNumber',
    features: { attachments: true, comments: true },
    requiredPolicy: 'Transactions.Proformas',
    tabs: [{ path: 'preview', iconClass: 'file-search' }],
    crudAction: {
      list: 'pages/proforma',
      read: 'pages/proforma',
      create: 'pages/proforma',
      // update: 'pages/proforma',
      update: CrudAction.hidden,
      delete: CrudAction.hidden,
    },
  },

  {
    path: 'projectdetail',
    iconClass: 'project',
    lookupFieldName: 'projectNo',
    lookupFieldCode: 'subGroupName',
    features: { comments: true },
    tabs: [
      { path: 'contacts', iconClass: 'contacts' },
      { path: 'advance', requiredPolicy: 'Transactions.ProjectAdvances', iconClass: 'dollar' },
      { path: 'task', iconClass: 'unordered-list', requiredPolicy: 'Transactions.ProjectTasks' },
      { path: 'check-list', iconClass: 'ordered-list', requiredPolicy: 'Transactions.ProjectCheckLists' },
      { path: 'edda', requiredPolicy: 'Transactions.ProjectEDDAs', iconClass: 'file-sync' },
      { path: 'invoices', requiredPolicy: 'Masters.Invoices', iconClass: 'file-text' },
      {
        path: 'workdrive',
        requiredPolicy: 'Transactions.WorkDrive',
        iconClass: 'folder-open',
      },
      // { path: 'fda', requiredPolicy: 'Transactions.ProjectFDAs', iconClass: 'file-protect' },
      { path: 'fdav2', requiredPolicy: 'Transactions.ProjectFDAs', iconClass: 'file-protect' },
      { path: 'credit-note', requiredPolicy: 'Transactions.ProjectCreditNotes', iconClass: 'credit-card', entity: 'projectCreditNote'},
    ],
    requiredPolicy: 'Transactions.ProjectDetails',
    crudAction: {
      list: 'pages/projectdetail',
      read: 'pages/projectdetail',
      create: CrudAction.hidden,
      update: CrudAction.hidden,
      delete: CrudAction.hidden,
    },
  },

  { path: 'sop' },
  { path : 'projectCheckList'},
  {
    path: 'workdrive',
    entity: 'projectWorkDrive',
    iconClass: 'folder-open',
    crudAction: {
      create: CrudAction.hidden,
      update: CrudAction.hidden,
      delete: CrudAction.hidden,
    },
  },
  {
    path: 'projectAttachment',
    iconClass: 'folder-open',
  },

  // Invoice
  {
    path: 'allinvoice',
    iconClass: 'file-text',
    requiredPolicy: 'Transactions.Invoices',
    lookupFieldName: 'invoiceNo',
    lookupFieldCode: 'details',
    crudAction: {
      list: 'pages/allinvoice',
      read: 'pages/allinvoice',
      create: 'pages/allinvoice',
      update: 'pages/allinvoice',
    },
  },

  // ProjectTask
  {
    path: 'projectTask',
    iconClass: 'unordered-list',
    invisible: true,
    requiredPolicy: 'Transactions.ProjectTasks',
    lookupFieldName: 'taskItemName',
    lookupFieldCode: 'userProfileName',
  },

  // All Task
  {
    path: 'alltask',
    entity: 'projectTask',
    iconClass: 'unordered-list',
    requiredPolicy: 'Transactions.ProjectTasks',
    lookupFieldName: 'taskItemName',
    lookupFieldCode: 'userProfileName',
    features: { comments: true },
    crudAction: {
      list: 'pages/alltask',
      read: 'pages/alltask',
      create: CrudAction.hidden,
      update: 'pages/alltask',
    },
    invisible: true,
  },

   // ProjectCreditNote
   {
    path: 'credit-note',
    invisible: true,
    requiredPolicy: 'Transactions.ProjectCreditNotes', 
    crudAction: {
      create: CrudAction.hidden
    },
  },
] as AppMenuDto[];
